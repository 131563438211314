import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AgeRange } from '@index/enums/age-range';
import firebase from 'firebase/compat';

import { EventItemTypes } from '../enums/event-item-type';
import { SkillLevel } from '../enums/skill-level';
import { SubscriptionType } from '../enums/subscription-type';
import { Location } from './location';
import { TeamWebsite } from './team';
import { User } from './user';

/**
 * Represents GTH/MEH Event
 */
export interface EventItem {
  id?: string;
  creator: string;
  title: string;
  gameType: string;
  isPrivate: boolean;
  duration: { hours: number; minutes: number };
  location: Location;
  teamName: string,
  hostingTeam: string | UnregisteredTeamInfo;
  cost?: number;
  dateStart: Date
  groups?: string[];
  equipmentNeeded: string[];
  makePublicAfter: number | null;
  minimumNeeded?: number | null,
  hoursToCancelBefore: number | null,
  creatorApprovalNeeded: boolean;
  allowParticipantGuests: boolean;
  eventSlotGroup: EventSlotGroup[];
  type?: EventItemTypes;
  description?: string;
  eventTeamsOnly?: null| boolean;
  joiners: EventJoiner[]
  banner?: string;
  online?: boolean;
  cancelled?: boolean;
  updated: firebase.firestore.Timestamp;
  rated: boolean;
  skillLevel?: SkillLevel;
  discoverable?: boolean;
  theme?: EventTheme;
  backgroundColor?: string;
  sendFeedbackEmailAfter?: boolean;
  ticketLevels?: EventTicketLevel[];
  platform?: string;
  priceId?: string,
  survey?: EventSurvey,
  allowMaybeRsvp?: boolean,
  requireGuestInformation?: boolean,
  allowUnregistered?: boolean,
  favoritedList: string[],
  isBasic?: boolean,
}


export interface EventGroupItem {
  name: string;
}

export interface EventSlotGroup {
  date: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  slots: EventSlot[],
}

export interface EventSlot {
  updatedAt: firebase.firestore.Timestamp;
  number: number; // Use this to track, name is mutable
  name: string,
  capacity: number,
  groupsList?: string[],
}

export interface UnregisteredTeamInfo {
  name: string;
  photoURL: string;
  id: null;
  gameType: string;
  discoverable: boolean;
  subscription: SubscriptionType;
  ageRange: AgeRange | string;
  location?: Location;
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
  website: TeamWebsite;
  displayPublicWebsite: boolean;
  playerCount?: number;
  online?: boolean;
  skillLevel?: SkillLevel,
}

export interface EventJoiner {
  player: string;
  createdAt: any;
  status?: EventJoinerStatus;
  approvedBy?: string[];
  guests?: EventItemGuest[];// Todo(Ajones): delete this!
  rsvpStatus: EventRsvpStatus;
  isGuestUser?: boolean;
  isUnregisteredUser?: boolean;
  slotNumber?: number;
  guestOfParticipant?: boolean;
  surveyAnswers?: any[],
  groupName?: string,
  eventTeamsOnly?: string,
  waitListDateTime?: Date,
}

export enum EventRsvpStatus {
  PLAYING = 'Playing',
  ATTEMPTING = 'Attempting',
  SPECTATING = 'Spectating',
  MAYBE = 'Maybe',
  NOT_PLAYING = 'Not Playing',
  WAITLISTED = 'Waitlisted',
}

export enum EventJoinerStatus {
  Approved = 'Approved',
  Waitlisted = 'Waitlisted',
  PendingCreator = 'Pending Creator',
  PendingApprovers = 'Pending Approvers',
  Dropped = 'Dropped',
  Denied = 'Denied',
  PendingJoiner = 'Pending Joiner',
  /** After event statuses */
  Attended = 'Attended',
  NoShowed = 'No Showed',
  Removed = 'Removed',
  NotCommited = 'Not Commited',
}


export enum UpdateType {
  ADD_JOINER = 'addJoiner',
  DELETE_JOINER = 'deleteJoiner',
  UPDATE_EVENT = 'updateEvent',
}

export interface UpdateEventItemRequest {
  creator: string;
  id: string;
  title?: string;
  dateStart?: Date;
  duration?: { hours: number; minutes: number };
  minimumNeeded: number,
  hoursToCancelBefore: number,
  location?: Location;
  hostingTeam: unknown;
  teamColor?: string;
  equipmentNeeded?: string[];
  makePublicAfter?: number;
  creatorApprovalNeeded?: boolean;
  allowParticipantGuests?: boolean;
  joiner: User;
  type: UpdateType;
  days?: string[];
  banner?: string;
  cancelled?: boolean;
}

export interface EventItemDeleteRequest {
  eventId: string;
  userId?: string;
}

export interface EventItemDuration {
  hours: number;
  minutes: number;
}

export interface EventJoinerUpdateRequest {
  status: EventJoinerStatus;
  player: string;
  event: string;
  rsvpStatus?: EventRsvpStatus;
  guests?: EventItemGuest[];
}

export interface GthEventItemListFilter {
  eventItemId?: string;
  lat?: number;
  lng?: number;
  userId?: string;
  teamId?: string;
}

export interface GthEventJoiner {
  player: string
  status: EventJoinerStatus;
  assignedGenderSpot?: string;
  approvedBy: string[];
  createdAt: firebase.firestore.Timestamp;
  user?: unknown;
  slotName?: string;
  guestOfParticipant?: boolean;
  eventTeamsOnly: string;
}

// make sure the select themselves first

export interface EventItemGuest {
  type: string;
  slotName: string,
  number: number,
}

export enum EventTheme {
  Easter = 'Easter',
  Birthday = 'Birthday',
  Valentines = 'Valentines',
  Sports = 'Sports',
}

export interface EventTicketLevel {
  name: string,
  cost: number,
  priceId: string,
  slotNumber: number | null,
}

// export interface EventItemFormGroup {
//   id: FormControl<string>,
//   title: FormControl<string>,
//   dateStart: FormControl,
//   duration: FormControl,
//   location: FormControl<Location>,
//   gameType: FormControl,
//   teamName: FormControl<string>,
//   hostingTeam: FormControl<string | UnregisteredTeamInfo>,
//   eventSlotGroup: FormArray<FormGroup<EventSlotGroupFormGroup>>,
//   groups: FormArray<FormControl<string>>,
//   cost: FormControl<number>,
//   equipmentNeeded: FormControl,
//   equipmentNeededItem: FormControl,
//   makePublicAfter: FormControl,
//   creatorApprovalNeeded: FormControl,
//   minimumNeeded: FormControl<number>,
//   hoursToCancelBefore: FormControl<number>,
//   allowParticipantGuests: FormControl,
//   banner: FormControl,
//   description: FormControl,
//   online: FormControl,
//   eventTeamsOnly: FormControl< null | boolean>,
//   eventType: FormControl,
//   skillLevel: FormControl,
//   days: FormControl,
//   theme: FormControl<EventTheme | null | undefined>,
//   backgroundColor: FormControl<string | null>,
//   sendFeedbackEmailAfter: FormControl<boolean>,
//   ticketLevels: FormArray<FormGroup<EventTicketLevelFormGroup>>,
//   priceId: FormControl<string | null>,
//   survey: FormControl,
//   allowMaybeRsvp: FormControl<boolean>,
//   requireGuestInformation: FormControl<boolean>,
//   allowUnregistered: FormControl<boolean>,
//   isBasic: FormControl<boolean>,
// }

export interface EventItemFormGroup {
  general: FormGroup<{
    title: FormControl<string>;
    gameType: FormControl;
    dateStart: FormControl;
    location: FormControl<Location>;
    duration: FormControl;
    online: FormControl;
    privacy: FormControl;
    description: FormControl;
    banner: FormControl;
    theme: FormControl<EventTheme | null | undefined>;
    backgroundColor: FormControl<string | null>;
    days: FormControl;
  }>;
  teams: FormGroup<{
    hostTeamEvent: FormControl<boolean>;
    hostingTeam: FormControl<string | UnregisteredTeamInfo>;
    teamName: FormControl<string>;
    eventTeamsOnly: FormControl<null | boolean>;
    eventType: FormControl;
  }>;
  participants: FormGroup<{
    groups: FormArray<FormControl<string>>;
    eventSlotGroup: FormArray<FormGroup<EventSlotGroupFormGroup>>;
    isBasic: FormControl<boolean>;
    basicParticipantAmount: FormControl<number>;
    allowUnregistered: FormControl<boolean>;
    requireGuestInformation: FormControl<boolean>;
    allowMaybeRsvp: FormControl<boolean>;
    allowParticipantGuests: FormControl;
    hasMinimumNeeded: FormControl<boolean>;
    minimumNeeded: FormControl<number>;
    hoursToCancelBefore: FormControl<number>;
    skillLevel: FormControl;
    creatorApprovalNeeded: FormControl;
  }>;
  details: FormGroup<{
    cost: FormControl<number>;
    ticketLevels: FormArray<FormGroup<EventTicketLevelFormGroup>>;
    equipmentNeeded: FormControl;
    equipmentNeededItem: FormControl;
    sendFeedbackEmailAfter: FormControl<boolean>;
    makePublicAfter: FormControl;
    priceId: FormControl<string | null>;
    id: FormControl<string>;
    survey: FormGroup<EventSurveyFormGroup>;
  }>;
}

export interface EventTicketLevelFormGroup {
  name: FormControl<string>,
  cost: FormControl<number>,
  priceId: FormControl<string>,
  slotNumber: FormControl<number>,
}

export interface EventGroupFormGroup {
  name: FormControl<string>,
}

export interface EventSlotGroupFormGroup {
  date: FormControl<firebase.firestore.Timestamp | null>,
  updatedAt: FormControl<firebase.firestore.Timestamp>,
  slots: FormArray<FormGroup<EventSlotFormGroup>>,
}

export interface EventSlotFormGroup {
  updatedAt: FormControl<firebase.firestore.Timestamp>,
  number: FormControl<number>,
  name: FormControl<string>,
  capacity: FormControl<number>,
  groupsList?: FormArray<FormControl<string>>,
  applyToDate: FormControl<firebase.firestore.Timestamp>,
}

export interface EventSurveyFormGroup {
  description: FormControl<string>,
  questions: FormArray<FormGroup<SurveyQuestionFormGroup>>,
  required: FormControl<boolean>,
}

export interface SurveySelectionFormGroup {
  value: FormControl<string | number>,
}

export interface SurveyQuestionFormGroup {
  text: FormControl<string>,
  required: FormControl<boolean>,
  answerType: FormControl<AnswerType>,
  page: FormControl<number>,
  selections: FormArray<FormGroup<SurveySelectionFormGroup>>,
}

export interface EventQuestion {
  text: string;
  required: boolean;
  answerType: AnswerType;
  page: number;
  selections?: EventSelection[]
}

export enum AnswerType {
  Short_Text = 'text',
  Checkbox = 'checkbox',
  Selection = 'selection',
  Number = 'number',
}

export interface EventSurvey {
  description: string;
  questions: EventQuestion[];
  required: boolean;
}

export interface EventSelection {
  value: string | number;
}
